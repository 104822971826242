/// <reference types="vite/client" />

// Alpine
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'

Alpine.plugin(intersect)

// Css
import 'glightbox/dist/css/glightbox.css'
import 'vanillajs-datepicker/css/datepicker.css'
import '../css/app.css'

// Splide CSS
import '@splidejs/splide/css/core'

// Components
import { initCollapsible } from './collapsible'
import { initHeader } from './header'
import { initHero } from './hero'
import { initImageSlider } from './image-slider'
import { initImagesSlider } from './images-slider'
import { initIntersectObserver } from './intersect-observer'
import { initJSEssentials } from './js-essentials'
import { initLightbox } from './lightbox'
import { initMenuToggle } from './menu-toggle'
import { initNavigationSlider } from './navigation-slider'
import { initQuoteSlider } from './quote-slider'
import { initShowOverview } from './show-overview'
import { initShowsSlider } from './shows-slider'
import { initSpeculationRules } from './speculation-rules'
import { initSubmenu } from './submenu'
import { initSocialShare } from './social-share'
import { initSearchResults } from './search-results'
import { initWhatsapp } from './whatsapp'

initCollapsible()
initHeader()
initHero()
initImageSlider()
initImagesSlider()
initIntersectObserver()
initJSEssentials()
initLightbox()
initMenuToggle()
initNavigationSlider()
initQuoteSlider()
initShowOverview()
initShowsSlider()
initSpeculationRules()
initSubmenu()
initSocialShare()
initSearchResults()
initWhatsapp()

Alpine.start()

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR')
    })
}
