import Alpine from 'alpinejs'

export const initSearchResults = () => {
    Alpine.data('searchResults', () => ({
        colors: ['fuchsia', 'sea', 'grass', 'rose'],
        chosenColor: '',
        init() {
            this.chosenColor = this.colors[Math.floor(Math.random() * this.colors.length)]
        },
        goToPage(page: string) {
            location.href = page
        },
    }))
}
