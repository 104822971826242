import Splide from '@splidejs/splide'
import Alpine from 'alpinejs'

export const initQuoteSlider = () => {
    Alpine.data('initQuoteSlider', () => ({
        init() {
            new Splide(this.$el, {
                arrows: true,
                pagination: false,
                perMove: 1,
                perPage: 1,
            }).mount()
        },
    }))
}
