import Alpine from 'alpinejs'
import { Datepicker } from 'vanillajs-datepicker'
import nl from 'vanillajs-datepicker/locales/nl'

Object.assign(Datepicker.locales, nl)

type IApiResponse = {
    success: boolean
    shows: string
    filters: {
        dates: { [x: string]: string }
        genres: { id: number; value: string; title: string; disabled: boolean }[]
        theatres: { id: number; value: string; title: string; disabled: boolean }[]
        themes: { id: number; value: string; title: string; disabled: boolean }[]
        subgenres: { id: number; value: string; title: string; disabled: boolean; visible: boolean }[]
        subgenreVisible: boolean
    }
    seo: {
        title: string
        description: string
    }
}

const formatDate = (date: Date | null) => {
    if (!date || date.toString() === 'Invalid Date') {
        return ''
    }

    let day = date.getDate().toString().padStart(2, '0')
    let month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
    let year = date.getFullYear()

    return `${day}-${month}-${year}`
}

const formatDate2 = (date: Date | null) => {
    if (!date || date.toString() === 'Invalid Date') {
        return ''
    }

    let day = date.getDate().toString().padStart(2, '0')
    let month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
    let year = date.getFullYear()

    return `${year}-${month}-${day}`
}

const parseDate = (dateString: string | null) => {
    if (!dateString) {
        return null
    }

    let [day, month, year] = dateString.split('-').map(Number)
    return new Date(year, month - 1, day) // Month is zero-based in JS Date
}

export const initShowOverview = () => {
    Alpine.data('showOverview', () => ({
        colors: ['fuchsia', 'sea', 'grass', 'rose'],
        filterDate: '',
        filterGenre: '',
        filterTheatre: '',
        filterTheme: '',
        filterSubgenre: '',
        chosenColor: '',
        datePicker: null as Datepicker | null,
        dateElement: null as HTMLInputElement | null,
        debouncedFilter: null as ((queryParams: URLSearchParams) => void) | null,
        init() {
            this.debouncedFilter = Alpine.debounce((qp: URLSearchParams) => {
                this.filter(qp)
            }, 200)

            this.dateElement = document.querySelector('input[name="filterDates"]')

            if (this.dateElement) {
                this.datePicker = new Datepicker(this.dateElement, {
                    format: 'dd-mm-yyyy',
                    language: 'nl',
                    datesDisabled: (date: Date, viewId: number) => {
                        if (viewId != 0) return

                        const availableDates = JSON.parse(this.dateElement?.getAttribute('data-available-dates') || '{}')
                        const cDate = formatDate2(date)

                        return !availableDates[cDate]
                    },
                })
            }

            document.addEventListener('changeDate', (e: CustomEvent) => {
                this.filterDate = formatDate(new Date(e.detail.date))
            })

            this.chosenColor = this.colors[Math.floor(Math.random() * this.colors.length)]

            const filterDate = parseDate(new URLSearchParams(window.location.search).get('date'))
            this.filterDate = formatDate(filterDate)

            if (filterDate !== null && new Date(filterDate).toString() !== 'Invalid Date') {
                this.datePicker?.setDate(filterDate)
            }

            const path = window.location.pathname.split('/').slice(1)
            let genre = ''

            if (path[0] === 'de') {
                if (path[2]) {
                    genre = decodeURIComponent(path[2])
                }
            } else {
                if (path[1]) {
                    genre = decodeURIComponent(path[1])
                }
            }

            this.filterGenre = (genre || new URLSearchParams(window.location.search).get('genre') || '').toLowerCase()
            this.filterTheatre = new URLSearchParams(window.location.search).get('theatre') || ''
            this.filterTheme = new URLSearchParams(window.location.search).get('theme') || ''
            this.filterSubgenre = new URLSearchParams(window.location.search).get('subgenre') || ''

            this.$watch('filterDate', () => this.updateFilter())
            this.$watch('filterGenre', () => this.updateFilter())
            this.$watch('filterTheatre', () => this.updateFilter())
            this.$watch('filterTheme', () => this.updateFilter())
            this.$watch('filterSubgenre', () => this.updateFilter())
        },
        clearFilter(filter: string) {
            switch (filter) {
                case 'date':
                    this.filterDate = ''
                    this.dateElement && (this.dateElement.value = '')
                    this.datePicker?.setDate(null)
                    break
                case 'genre':
                    this.filterGenre = ''
                    this.filterSubgenre = ''
                    break
                case 'theatre':
                    this.filterTheatre = ''
                    break
                case 'theme':
                    this.filterTheme = ''
                    break
                case 'subgenre':
                    this.filterSubgenre = ''
                    break
                default:
                    break
            }
        },
        getFiltersForPagination() {},
        updateFilter() {
            const qp = new URLSearchParams()

            const path = window.location.pathname.split('/').slice(1)
            let baseUrl = ''

            if (path[0] === 'de') {
                baseUrl = '/' + path.slice(0, 2).join('/')
            } else {
                baseUrl = '/' + path.slice(0, 1).join('/')
            }

            if (this.filterGenre) {
                baseUrl += '/' + this.filterGenre
            }

            if (this.filterDate) {
                qp.set('date', this.filterDate)
            } else {
                qp.delete('date')
            }

            if (this.filterTheatre) {
                qp.set('theatre', this.filterTheatre)
            } else {
                qp.delete('theatre')
            }

            if (this.filterTheme) {
                qp.set('theme', this.filterTheme)
            } else {
                qp.delete('theme')
            }

            if (this.filterSubgenre) {
                qp.set('subgenre', this.filterSubgenre)
            } else {
                qp.delete('subgenre')
            }

            baseUrl = baseUrl.replace('//', '/')

            history.replaceState(null, '', baseUrl + '?' + qp.toString())

            if (this.filterGenre) {
                qp.set('genre', this.filterGenre)
            } else {
                qp.delete('genre')
            }

            this.debouncedFilter?.(qp)
        },
        goToPage(page: string) {
            const query = new URLSearchParams(window.location.search)

            if (query.get('genre') || query.get('theatre') || query.get('date')) {
                query.delete('page')
                location.href = page + '&' + query.toString()
            } else {
                location.href = page
            }
        },
        async filter(queryParams: URLSearchParams) {
            try {
                const path = window.location.pathname.split('/').slice(1)
                let baseUrl = ''

                if (path[0] === 'de') {
                    baseUrl = '/de/actions/_itix-filter/filter/refresh-filters'
                } else {
                    baseUrl = '/actions/_itix-filter/filter/refresh-filters'
                }

                const urlWithParams = `${baseUrl}?${queryParams.toString()}`

                const response = await fetch(urlWithParams)
                const html = (await response.json()) as IApiResponse

                this.$refs.shows.outerHTML = html.shows

                html.filters.genres.forEach((genre) => {
                    if (genre.disabled) {
                        this.$refs[`genre-${genre.id}`].setAttribute('disabled', 'disabled')
                    } else {
                        this.$refs[`genre-${genre.id}`].removeAttribute('disabled')
                    }
                })

                html.filters.theatres.forEach((theatre) => {
                    if (theatre.disabled) {
                        this.$refs[`theatre-${theatre.id}`].setAttribute('disabled', 'disabled')
                    } else {
                        this.$refs[`theatre-${theatre.id}`].removeAttribute('disabled')
                    }
                })

                html.filters.themes.forEach((theme) => {
                    if (theme.disabled) {
                        this.$refs[`theme-${theme.id}`].setAttribute('disabled', 'disabled')
                    } else {
                        this.$refs[`theme-${theme.id}`].removeAttribute('disabled')
                    }
                })

                html.filters.subgenres.forEach((subgenre) => {
                    if (subgenre.disabled) {
                        this.$refs[`subgenre-${subgenre.id}`].setAttribute('disabled', 'disabled')
                    } else {
                        this.$refs[`subgenre-${subgenre.id}`].removeAttribute('disabled')
                    }

                    if (subgenre.visible) {
                        this.$refs[`subgenre-${subgenre.id}`].classList.remove('hide')
                    } else {
                        this.$refs[`subgenre-${subgenre.id}`].classList.add('hide')
                    }
                })

                if (html.filters.subgenreVisible) {
                    this.$refs['subgenreFilter'].classList.remove('hide')
                } else {
                    this.$refs['subgenreFilter'].classList.add('hide')
                }

                this.$refs.genreTitle.innerText = html.seo.title
                this.$refs.genreDescription.innerHTML = html.seo.description

                this.$refs.filterDatesInput.setAttribute('data-available-dates', JSON.stringify(html.filters.dates))
                this.datePicker?.refresh('picker', true)
            } catch (error) {
                console.error(error)
            }
        },
    }))
}
