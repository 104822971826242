import Splide from '@splidejs/splide'
import Alpine from 'alpinejs'

export const initImageSlider = () => {
    Alpine.data('initImageSlider', () => ({
        init() {
            new Splide(this.$el, {
                gap: 30,
                isNavigation: false,
                arrows: true,
                pagination: false,
            }).mount()
        },
    }))
}
