import Splide from '@splidejs/splide'
import Alpine from 'alpinejs'

export const initImagesSlider = () => {
    Alpine.data('initImagesSlider', () => ({
        init() {
            new Splide(this.$el, {
                isNavigation: false,
                arrows: true,
                pagination: false,
                perMove: 1,
                updateOnMove: true,
                trimSpace: false,
                focus: 0,
                mediaQuery: 'min',
                lazyLoad: 'sequential',
                breakpoints: {
                    0: {
                        perPage: 1.1,
                        gap: 15,
                    },
                    500: {
                        perPage: 2.1,
                    },
                    900: {
                        perPage: 3.2,
                        gap: 30,
                    },
                    1200: {
                        perPage: 4.2,
                    },
                    1850: {
                        perPage: 4,
                    },
                },
            }).mount()
        },
    }))
}
