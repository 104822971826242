import Alpine from 'alpinejs'

export const initWhatsapp = () => {
    Alpine.data('whatsapp', () => ({
        whatsappClass: '',
        init() {
            this.setupScrollListener()
        },
        setupScrollListener() {
            window.addEventListener('scroll', this.handleScroll.bind(this))
        },
        handleScroll() {
            const currentScroll = window.scrollY

            if (currentScroll > 200) {
                if (!this.whatsappClass.includes('whatsapp-button--collapsed')) {
                    this.whatsappClass += ' whatsapp-button--collapsed'
                }
            } else {
                this.whatsappClass = this.whatsappClass.replace(' whatsapp-button--collapsed', '')
            }
        },
    }))
}
