import Alpine from 'alpinejs'

export const initSocialShare = () => {
    Alpine.data('socialShare', () => ({
        init() {},
        handleClick(event: Event) {
            event.preventDefault()

            const target = event.currentTarget as HTMLAnchorElement
            const url = target.href

            if (navigator.share) {
                navigator.share({
                    title: document.title,
                    url: url,
                })
            } else {
                const body = encodeURIComponent(`${url}`)
                const mailtoUrl = `mailto:?body=${body}`
                window.location.href = mailtoUrl
            }
        },
    }))
}
